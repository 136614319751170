import React, { useState } from "react"
import { graphql } from "gatsby"
import Contact from '../../components/pages/Contact'

const lang = 'es'

const ContactUs = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_es,
    titleSeo: seoDataJson.title_es,
    description: seoDataJson.description_es,
    alternateLanguage: seoDataJson.alternateLanguage_es,
    alternateUrl: seoDataJson.alternateUrl_es,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "CONTACT-US"
  })

  return (
    <Contact
      lang={lang}
      seoData={seoData}
      urls={resultUrls}
      pageContext={pageContext}
    />
  )
}

export const pageQuery = graphql`
  query contactUsEsQuery {
    seoDataJson(name: { eq: "contact-us" }) {
      url_es
      title_es
      description_es
      alternateLanguage_es
      alternateUrl_es
    }
  }
`

export default ContactUs
